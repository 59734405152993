import React from 'react';

import seal from '../grace-meng-header-cropped.png'

const Introduction = () => {
    return (<div>
        <img className="bottom-margin-2" src={seal} alt="grace meng seal"/>
        <h2 className="center-text">About the Internship</h2>
        <p>Our program is designed to provide unique opportunities to learn first-hand about the political process and the interaction between the congresswoman and her constituency. The District office interns will have an opportunity to develop research skills and learn how to interact with constituents by helping to answer their many questions regarding government policies. When possible, interns will have the opportunity to attend meetings, draft constituents’ correspondence, answer telephones and enter data; in short, they will be full participants in the working life of the office.</p>
    </div>)
}

export default Introduction