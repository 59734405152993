import React from 'react';


const Schedule = () => {
    return (
        <div>
            <h2>Schedule</h2>
            <div className="row bottom-margin-2">
                <div className="column">
                    <label htmlFor="desiredStartField">Desired Start Date</label>
                    <input type="date" id="desiredStartField" />
                </div>

                <div className="column">
                    <label htmlFor="desiredEndField">Desired End Date</label>
                    <input type="date" id="desiredEndField" />
                </div>
            </div>

            <p>If I am selected for Congresswoman Meng’s internship program, I will be available at the following times each week in the district office: </p>

            <label htmlFor="mondayField">Monday: </label>
            <input type="text" id="mondayField" />

            <label htmlFor="tuesdayField">Tuesday: </label>
            <input type="text" id="tuesdayField" />

            <label htmlFor="wednesdayField">Wednesday: </label>
            <input type="text" id="wednesdayField" />

            <label htmlFor="thursdayField">Thursday: </label>
            <input type="text" id="thursdayField" />

            <label htmlFor="fridayField">Friday: </label>
            <input type="text" id="fridayField" />

            <h2>Resume/Cover Letter</h2>

            <label htmlFor="resumeField">Resume: </label>
            <input type="file" id="resumeField" />

            <label htmlFor="coverLetterField">Cover Letter: </label>
            <input type="file" id="coverLetterField" className="bottom-margin-5"/>

        </div>
    )
}

export default Schedule