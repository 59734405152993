import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';

// Create styles
const styles = StyleSheet.create({
    section: {
        margin: 10,
        padding: 10,
    },
    header: {
        marginBottom: 10,
    },
    wrapper: {
        display: 'flex',
        marginBottom: 10
    }
});

const Field = styled.Text`
  font-size: 12px;
  padding-left: 5;
  margin-bottom: 10;
`

const ApplicationPDF = ({details, fullName}) => {
    return (
        <Document>
            <Page size="A4">
                <View style={styles.section}>
                    <Text style={styles.header}>Applicant Details</Text>
                    <Text style={styles.wrapper}>
                        <Field>Name:</Field><Field>{details.name}</Field>
                    </Text>
                    <Text style={styles.wrapper}>
                        <Field>Birthday:</Field><Field>{details.birthday}</Field>
                    </Text>
                    <Text style={styles.wrapper}>
                        <Field>Home Phone:</Field><Field>{details.homePhone}</Field>
                    </Text>
                    <Text style={styles.wrapper}>
                        <Field>Cell Phone:</Field><Field>{details.cellPhone}</Field>
                    </Text>
                    <Text style={styles.wrapper}>
                        <Field>School Credit:</Field><Field>{details.schoolCredit}</Field>
                    </Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.header}>Employment</Text>
                    <Field>--- Employment Information goes here ---</Field>
                    <Text style={styles.header}>Emergency Contact</Text>
                    <Field>--- Emergency Contact Information goes here ---</Field>
                    <Text style={styles.header}>Work Eligibility</Text>
                    <Field>--- Work Eligibility goes here ---</Field>
                    <Text style={styles.header}>Education</Text>
                    <Field>--- Education goes here ---</Field>
                    <Text style={styles.header}>References (1)</Text>
                    <Field>--- Reference 1 information---</Field>
                    <Text style={styles.header}>References (2)</Text>
                    <Field>--- Reference 2 information---</Field>
                    <Text style={styles.header}>Schedule</Text>
                    <Field>--- Schedule information---</Field>
                </View>
                <View style={styles.section}>
                    <Text style={styles.header}>Signed Full Name: </Text>
                    <Text style={{
                        fontSize: '15',
                        fontStyle: 'oblique',
                    }}>{fullName}</Text>
                </View>
            </Page>
        </Document>
    )
}

export default ApplicationPDF