import React, {Fragment} from 'react';

const Education = () => {
    return (<Fragment>
        <h2>Education</h2>

        <label htmlFor="schoolNameField">Name of School</label>
        <input type="text" id="schoolNameField" />

        <label htmlFor="schoolAddressField">Address</label>
        <input type="text" id="schoolAddressField" />

        <label htmlFor="schoolPhoneField">Phone Number</label>
        <input type="text" id="schoolPhoneField" />

        <div className="row">
            <div className="column">
                <label htmlFor="majorAndGPAField">Major & GPA</label>
                <input type="text" id="majorAndGPAField" />
            </div>
            <div className="column">
                <label htmlFor="degreeGraduationField">Degree & Graduation Date</label>
                <input type="text" id="degreeGraduationField" />
            </div>
        </div>
    </Fragment>)
}

const References = () => {
    return (
        <Fragment>

            <div className="row">
                <div className="column">
                    <label htmlFor="referenceNameField">Name</label>
                    <input type="text" id="referenceNameField" />
                </div>

                <div className="column">
                    <label htmlFor="referencePhoneField">Phone Number</label>
                    <input type="text" id="referencePhoneField" />
                </div>
            </div>

            <div className="row">
                <div className="column">
                    <label htmlFor="orgSchoolField">Organization/School</label>
                    <input type="text" id="orgSchoolField" />
                </div>

                <div className="column">
                    <label htmlFor="emailField">Email</label>
                    <input type="text" id="emailField" />
                </div>
            </div>

            <div className="row">
                <div className="column">
                    <label htmlFor="relationshipField">Relationship</label>
                    <input type="text" id="relationshipField" />
                </div>

                <div className="column">
                    <label htmlFor="relationshipLengthField">Length of Relationship</label>
                    <input type="text" id="relationshipLengthField" />
                </div>
            </div>
        </Fragment>
    )
}

const EducationReferences = () => {
    return (
        <div>
            <Education/>
            <div className="header-with-small">
                <h2 className="no-bottom-margin">References</h2><small>(1)</small>
            </div>
            <References/>
            <div className="header-with-small">
                <h2 className="no-bottom-margin">References</h2><small>(2)</small>
            </div>
            <References/>
        </div>
    )
}

export default EducationReferences