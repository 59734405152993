import React, { Fragment } from 'react'

const Details = ({details, setDetails}) => {
    const handleChange = (event) => {
        event.persist()
        const field = event.target.name
        setDetails((prevState) => ({
            ...prevState,
            [field]: event.target.value
        }))
    }

    return (
        <Fragment>
            <h2>Applicant Details</h2>
            <label htmlFor="nameField">Name</label>
            <input type="text" name="name" id="nameField" value={details.name || ''} onChange={handleChange}/>

            <label htmlFor="dateBirthField">Date of Birth</label>
            <input type="date" name="birthday" id="dateBirthField" value={details.birthday || ''} onChange={handleChange}/>

            <label htmlFor="addressField">Address</label>
            <input type="text" name="address" value={details.address || ''} onChange={handleChange} id="addressField" />

            <div className="row">
                <div className="column">
                    <label htmlFor="homePhoneField">Home Phone</label>
                    <input type="text" name="homePhone" value={details.homePhone || ''} onChange={handleChange} id="homePhoneField" />
                </div>
                <div className="column">
                    <label htmlFor="cellPhoneField">Cell Phone</label>
                    <input type="text" name="cellPhone" value={details.cellPhone || ''} onChange={handleChange} id="cellPhoneField" />
                </div>
            </div>

            <label htmlFor="emailField">Email</label>
            <input type="email" id="emailField" name="email" value={details.email || ''} onChange={handleChange}/>

            <div className="row">
                <div className="column">
                    <label>Is this for school credit?</label>
                </div>
                <div className="column column-20 inline-radio-button">
                    <input type="radio" id="yes" name="schoolCredit" value="yes" onChange={handleChange} checked={(details.schoolCredit && details.schoolCredit === 'yes') || false}/>
                    <label htmlFor="yes">Yes</label>
                </div>
                <div className="column column-10 inline-radio-button">
                    <input type="radio" id="no" name="schoolCredit" value="no" onChange={handleChange} checked={(details.schoolCredit && details.schoolCredit === 'no') || false}/>
                    <label htmlFor="no">No</label>
                </div>
            </div>
        </Fragment>
    )
}

const Employment = () => {
    return (<Fragment>
        <div className="row header-with-small">
            <h2 className="no-bottom-margin">Current Employment</h2><small>(if applicable)</small>
        </div>

        <label htmlFor="supervisorNameField">Supervisor Name</label>
        <input type="text" id="supervisorNameField" />

        <label htmlFor="employmentField">Address</label>
        <input type="text" id="employmentField" />

        <div className="row">
            <div className="column">
                <label htmlFor="employmentPhoneField">Phone Number</label>
                <input type="text" id="employmentPhoneField" />
            </div>
            <div className="column">
                <label htmlFor="employmentEmailField">Email</label>
                <input type="email" id="employmentEmailField" />
            </div>
        </div>
    </Fragment>)
}

const Emergency = () => {
    return (
        <Fragment>
            <h2>Emergency Contact</h2>

            <div className="row">
                <div className="column">
                    <label htmlFor="contactNameField">Contact Name</label>
                    <input type="text" id="contactNameField" />
                </div>
                <div className="column">
                    <label htmlFor="relationField">Relation</label>
                    <input type="text" id="relationField" />
                </div>
            </div>

            <label htmlFor="contactLanguageField">Contact's Preferred Language</label>
            <input type="text" id="contactLanguageField" />

            <label htmlFor="addressField">Address</label>
            <input type="text" id="addressField" />

            <div className="row">
                <div className="column">
                    <label htmlFor="contactPhoneField">Phone Number</label>
                    <input type="text" id="contactPhoneField" />
                </div>
                <div className="column">
                    <label htmlFor="contactEmailField">Email</label>
                    <input type="email" id="contactEmailField" />
                </div>
            </div>
        </Fragment>
    )
}

const WorkEligibility = () => {
    return (
        <Fragment>
            <h2>Work Eligibility</h2>

            <div className="row">
                <div className="column">
                    <label>Are you eligible to work in the U.S.? </label>
                </div>
                <div className="column column-20">
                    <label htmlFor="yes">Yes</label>
                    <input type="radio" id="yes" name="workEligibility" value="yes"/>
                </div>
                <div className="column column-10">
                    <label htmlFor="no">No</label>
                    <input type="radio" id="no" name="workEligibility" value="no" />
                </div>
            </div>

            <div className="row bottom-margin-2">
                <div className="column flex-align-center">
                    <label>Please select: </label>
                </div>
                <div className="column inline-radio-button">
                    <input type="radio" id="citizen" name="citizenship" value="citizen" />
                    <label htmlFor="citizen">U.S. citizen</label>
                </div>
                <div className="column inline-radio-button">
                    <input type="radio" id="permanentResident" name="citizenship" value="permanentResident" />
                    <label htmlFor="permanentResident">Permanent Resident</label>
                </div>
                <div className="column column-40 inline-radio-button other">
                    <label htmlFor="other">Other (Specify)</label>
                    <input type="text" id="other"/>
                </div>
            </div>
        </Fragment>
    )
}

const InternData = ({details, setDetails}) => {
    return (
        <div>
            <Details setDetails={setDetails} details={details}/>
            <Employment setDetails={setDetails}/>
            <Emergency setDetails={setDetails}/>
            <WorkEligibility setDetails={setDetails}/>
        </div>
    )
}

export default InternData