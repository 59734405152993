import React, {useState} from 'react';
import MultiStep from 'react-multistep';
import './App.css';
import InternData from "./components/InternData"
import EducationReferences from "./components/EducationReferences"
import Schedule from "./components/Schedule"
import Terms from "./components/Terms"
import Introduction from "./components/Introduction"

function App() {
    const [details, setDetails] = useState({})
    const [education, setEducation] = useState({})
    const [schedule, setSchedule] = useState({})

    const steps = [
        {component: <Introduction/>},
        {component: <InternData details={details} setDetails={setDetails}/>},
        {component: <EducationReferences setEducation={setEducation}/>},
        {component: <Schedule setSchedule={setSchedule}/>},
        {component: <Terms details={details} education={education} schedule={schedule}/>},
    ];

    const prevStyle = {'float': 'left'}
    const nextStyle = {'float': 'right'}

  return (
      <div className="container">
            <div className="form center-margin bottom-margin-10">
                <MultiStep showNavigation={true} steps={steps} prevStyle={prevStyle} nextStyle={nextStyle}/>
            </div>
      </div>
  );
}

export default App;
