import React, {useState} from 'react';
import { PDFDownloadLink } from "@react-pdf/renderer";
import ApplicationPDF from "./ApplicationPDF"

const Terms = ({details}) => {
    const [fullName, setFullName] = useState("")

    return (
        <div>
            <h2>Terms</h2>
            <p>Please read and sign the following statement: </p>
            <p><i>I acknowledge that Congresswoman Grace Meng provides an internship program for qualified applicants in her congressional district office as well as in her legislative office. This program will teach the interns about the functions and workings of the federal government and how the different levels of government interact to provide programs and activities as well as an independent study project to help me achieve this goal. There is no remuneration of any kind for this internship in the district office. </i></p>

            <label htmlFor="fullNameField">Your Full Name (printed): </label>
            <input type="text" id="fullNameField" value={fullName} onChange={(e) => setFullName(e.target.value)}/>

            <PDFDownloadLink
                document={<ApplicationPDF details={details} fullName={fullName}/>}
                fileName="application.pdf"
                className="button"
                style={{
                    float: "right"
                }}
            >submit</PDFDownloadLink>
        </div>
    )
}

export default Terms